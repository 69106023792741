<template>
  <div class="withdraw-container">
<!--    <div class="withdraw-content">-->
<!--      <div class="box">-->
<!--        <div class="box-content">-->
<!--          <h2 class="box-header">{{ $tc('withdraw_header_1') }}</h2>-->
<!--          <div class="box-main-content">-->
<!--            <div class="left">-->
<!--              <div class="alert-text">{{ $tc('withdraw_description_1') }}</div>-->
<!--              <div class="copy-container">-->
<!--                <input type="text"-->
<!--                       v-model="withdrawAddress"-->
<!--                       :placeholder="$tc('address_hover')"-->
<!--                       class="input">-->
<!--                <v-select :clearable="false" v-model="currency" :options="stakeSelectOptions">-->
<!--                  <template v-slot:option="option">-->
<!--                    <img :src="option.icon" alt="">-->
<!--                    <span class="title">{{ option.label }}</span>-->
<!--                  </template>-->
<!--                  <template #selected-option="{ label, icon }">-->
<!--                    <img :src="icon" alt="">-->
<!--                    <span class="title">{{ label }}</span>-->
<!--                  </template>-->
<!--                </v-select>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="box">-->
<!--        <div class="box-content">-->
<!--          <h2 class="box-header" v-html="$tc('withdraw_header_2')"></h2>-->
<!--          <div class="alert-text">{{ $tc('withdraw_description_2') }}</div>-->
<!--          <div class="box-main-content">-->
<!--            <div class="left">-->
<!--              <div class="left-header">-->
<!--                <p class="item title">{{ $tc('minimum_amount') }}: <span> 100 DFORT</span></p>-->
<!--                <p class="item title">{{ $tc('available') }}: <span>{{ totalBalance }} DFORT</span></p>-->
<!--              </div>-->
<!--              <div class="copy-container">-->
<!--                <input type="number"-->
<!--                       @keyup="restrictValue"-->
<!--                       v-model="withdrawValue"-->
<!--                       class="input" placeholder="Amount of DF to withdraw">-->
<!--                <span class="about-title">{{ $tc("withdrawal_fee") }}: 1%</span>-->
<!--                <span class="about-title">You will get: {{ youWillGet }}</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="bottom">-->
<!--          <div class="about-container">-->
<!--            <img src="../../assets/images/withdraw_about.png" class="image" alt="">-->
<!--            <span class="title">{{ $tc('withdrawal_footer') }}</span>-->
<!--          </div>-->
<!--          <Button v-if="countDownTime<0" :event="sendWithdrawWallet"-->
<!--                  :successEvent="stakeCreateResponseEvent"-->
<!--                  :errorEvent="stakeCreateResponseEvent"-->
<!--                  :disable="withdrawValue<1"-->
<!--                  :title="$tc('withdraw')"/>-->
<!--          <vue-countdown v-else :time="countDownTime" v-slot="{ days, hours, minutes, seconds }">-->
<!--              <span class="timer">-->
<!--              {{ $tc("can_withdraw") }}：{{ days }} days, {{ hours }} hours, {{ minutes }} minutes, {{ seconds }} seconds.-->
<!--              </span>-->
<!--          </vue-countdown>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="box">
      <div class="table-container">
        <h2 class="title">Withdraw Transactions</h2>
        <v-data-table
            :headers="tableHeader"
            :items="withdrawTransaction"
            hide-default-footer
            class="elevation-1"
        >
          <template v-slot:item.balanceType="{ item }">
            <div class="table-flex">
              <span class="title">{{ item.balanceType }}</span>
              <img :src='item.tokenIcon' alt="">
            </div>
          </template>
          <template v-slot:item.fee="{ item }">
            <div class="table-flex">
              <span class="title">{{ item.fee }} %</span>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
// import Button from "../Button";
import {mapActions, mapGetters} from "vuex";
// import vSelect from 'vue-select'
// import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
  data() {
    return {
      withdrawValue: '',
      withdrawAddress: '',
      currency: {},
      stakeSelectOptions: [],
      lastTransaction: '',
      withdrawTransaction: []
    }
  },
  components: {
    // Button,
    // vSelect,
    // VueCountdown
  },
  computed: {
    ...mapGetters({
      balance: 'wallet/getBalance',
      userSettings: 'userSettings/getUserSettings',
      user: 'auth/getUser'
    }),
    totalBalance() {
      return Number(this.balance?.balance?.DFORT) || 0
    },
    nextWithdrawTime() {
      return this.moment(this.lastTransaction).add(30, 'days')
    },
    countDownTime() {
      let now = this.moment()

      return this.lastTransaction ? this.nextWithdrawTime.diff(now) || Date.now() : -1
    },
    tableHeader() {
      return [
        {
          text: 'Id',
          sortable: false,
          value: 'id',
        },
        {
          text: 'Status', value: 'requestStatus', sortable: false,
        },
        {
          text: 'Fee', value: 'fee', sortable: false,
        },
        {
          text: 'Withdraw Token', value: 'balanceType', sortable: false,
        },
        {
          text: 'Amount', value: 'amount', sortable: false,
        },
        {
          text: 'Date', value: 'createdAt', sortable: false,
        },
      ]
    },
    youWillGet() {
      return Math.round(this.withdrawValue * 99) / 100
    }
  },
  methods: {
    ...mapActions({
      withdrawWallet: 'wallet/withdrawWallet',
      getBalance: 'wallet/getBalance',
      getUserSettings: 'userSettings/getUserSettings'
    }),
    stakeCreateResponseEvent() {
      this.withdrawValue = null
    },
    sendWithdrawWallet() {
      return this.withdrawWallet({
        amount: this.withdrawValue,
        address: this.withdrawAddress,
        token: this.currency.label
      })
    },
    getPrices() {
      this.$axios.get('api/v1/wallet/withdraw/token-type').then(({data}) => {
        this.stakeSelectOptions = data?.map(item => ({
          label: item,
          icon: require(`@/assets/images/${item?.toLowerCase()}.png`)
        }));
        this.currency = this.stakeSelectOptions?.find(item => item.label === 'BUSD')
      })
    },
    getLastTransaction() {
      this.$axios.get('api/v1/wallet/last-withdraw').then(({data}) => {
        this.lastTransaction = data?.createdAt
      })
    },
    getWithdrawTransaction() {
      this.$axios.get(`api/v1/wallet/balance/withdraw`).then(({data}) => {
        this.withdrawTransaction = data?.map(item => ({
          ...item,
          amount: Math.round(item.amount * (100 - item.fee)) / 100,
          tokenIcon: require(`@/assets/images/${item.balanceType?.toLowerCase()}.png`),
          createdAt: this.moment(item.createdAt).format('DD/MM/YYYY')
        }))
      })

    },
    restrictValue() {
      if (this.withdrawValue > this.totalBalance) this.withdrawValue = this.totalBalance
    }
  },
  created() {
    this.getBalance();
    this.getPrices();
    this.getLastTransaction();
    this.getUserSettings()?.then(() => {
      this.withdrawAddress = this.userSettings?.withdrawAddress
    });
    this.getWithdrawTransaction()
  }
}
</script>

<style lang="scss" scoped>
.withdraw-container {
  width: 100%;
  padding: 22px 27px;
  height: 100%;
  @media only screen and (max-width: 1440px) and (min-width: 1024px) {
    padding: 20px;
  }
  @media only screen and (max-width: 1023px) {
    padding: 20px 19px;
  }

  .withdraw-content {
    background: linear-gradient(180deg, rgba(26, 29, 38, 0.5) 66.89%, rgba(17, 17, 17, 0) 128.34%);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 35px;
    min-height: 100%;
    @media only screen and (max-width: 1023px) {
      background: transparent;
      border-radius: unset;
      box-shadow: unset;
    }
  }

  .box {
    position: relative;
    padding: 29px 47px 42px;
    border-bottom: 1px solid rgba(250, 250, 250, .2);

    @media only screen and (max-width: 1800px) and (min-width: 1366px) {
      padding: 29px 30px 42px;
    }
    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
      padding: 20px;
    }
    @media only screen and (max-width: 1023px) {
      padding: 0;
      border-bottom: unset;
    }

    &:first-child {
      height: fit-content;
    }

    &:last-child {
      @media only screen and (max-width: 1023px) {
        margin-top: 25px;
      }

      .alert-text {
        margin-top: 20px;
      }
    }

    .number-container {
      @include center-children;
      min-width: 60.09px;
      min-height: 60.09px;
      border-radius: 50%;
      border: 1px solid $base-color;
      box-sizing: border-box;
      font-weight: 400;
      font-size: 25px;
      color: #42E8E0;
      margin-right: 55px;
      @media only screen and (max-width: 1800px) and (min-width: 1366px) {
        margin-right: 35px;
      }
      @media only screen and (max-width: 1365px) {
        min-width: 30px;
        min-height: 30px;
        font-size: 18px;
        color: #42E8E0;
        margin-right: 20px;
      }
    }

    .box-content {
      width: 100%;

      ::v-deep .v-select {
        width: 140px;
        border: 1px solid rgba(255, 255, 255, 0.38);
        height: 60px;
        border-radius: 10px;
        margin-left: 30px;
        background: #171717;
        @media only screen and (max-width: 767px) {
          margin-left: 0;
          margin-top: 20px;
          width: 100%;
        }

        img {
          height: 30px;
          width: 30px;
          object-fit: contain;
        }

        .title {
          color: white;
          font-size: 18px;
          margin-left: 10px;
          margin-bottom: 0;
        }

        .vs__dropdown-toggle {
          width: 100%;
          height: 100%;
          border: unset;
          padding: 0;

          .vs__selected-options {
            flex-grow: unset;
            flex-wrap: unset;
            padding: 0;
            flex-basis: unset;
            width: 100%;
            @include center-children;
          }

          .vs__selected {
            @include center-children;
            width: 100%;
            height: 100%;
            border: unset;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            color: #FFFFFF;
            font-family: "Rubik";
            padding: 0;
            margin: 0;
            @media only screen and (max-width: 767px) {
              justify-content: flex-start;
              padding-left: 20px;
            }
          }

          input {
            display: none;
          }
        }

        .vs__dropdown-menu {
          background: transparent;
          border: 1px solid #1e1e1e;
          padding: 0;
          border-top: unset;

          li {
            @include flex;
            padding: 5px 10px 5px 10px;
            background: #171717;

            &:first-child {
              margin-top: 0;
            }

            &:hover, &.vs__dropdown-option--selected, &.vs__dropdown-option--highlight {
              background: $base-color;

              .title {
                color: #0F0E15;
              }
            }

            .title {
              color: white;
              margin-left: 10px;
            }
          }
        }

        .vs__actions {
          //display: none;
          padding: 10px;

          svg {
            path {
              fill: white
            }
          }
        }
      }

      .box-header {
        @include flex;
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
        line-height: 35px;
        color: #FFFFFF;
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
          font-size: 20px;
          line-height: 25px;
        }
        @media only screen and (max-width: 1023px) {
          font-size: 18px;
          line-height: 25px;
        }

        span {
          font-weight: 700;
          color: $base-color;
          margin: 0 5px;
        }
      }

      .input {
        background: rgba(17, 17, 17, 0.66);
        border: 1px solid rgba(255, 255, 255, 0.38);
        box-sizing: border-box;
        border-radius: 10px;
        width: 503px;
        height: 60px;
        padding: 0 29px;
        font-size: 20px;
        color: $base-color;
        @media only screen and (max-width: 1800px) and (min-width: 1366px) {
          width: 400px;
          font-size: 18px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
          width: 300px;
          font-size: 16px;
          padding: 0 15px;
        }
        @media only screen and (max-width: 767px) {
          padding: 0 10px;
          font-size: 16px;
        }

        &::placeholder {
          color: rgba(66, 232, 224, 0.4);
        }
      }

      .copy-container {
        @include flex;
        margin-top: 30px;
        @media only screen and (max-width: 767px) {
          display: block;
        }

        .copy-button {
          @include center-children;
          padding: 0 35px;
          height: 60px;
          background: rgba(66, 232, 224, 0.3);
          border-radius: 70px;
          cursor: pointer;
          margin-left: 20px;
          @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            padding: 0 20px;
            height: 50px;
          }

          .btn-content {
            @include flex;

            .title {
              font-weight: 500;
              font-size: 20px;
              color: $base-color;
              margin-left: 15px;
            }
          }
        }
      }

      .alert-text {
        font-weight: 400;
        font-size: 18px;
        color: white;
      }

      .box-main-content {
        @include space-between;
        align-items: flex-end;
        margin-top: 40px;
        @media only screen and (max-width: 1365px) {
          display: block;
        }
        @media only screen and (max-width: 1023px) {
          margin-top: 25px;
        }

        .title {
          @include flex;
          font-weight: 400;
          font-size: 18px;
          color: #FFFFFF;
          @media only screen and (max-width: 1800px) {
            font-size: 16px;
          }
        }


        .left {
          .left-header {
            @include flex;
            @media only screen and (max-width: 500px) {
              display: block;
            }

            .title {
              margin-right: 24px;

              &:last-child {
                margin-right: 0;
                @media only screen and (max-width: 500px) {
                  margin-top: 10px;
                }
              }
            }

            span {
              color: $base-color;
              font-weight: 500;
              margin-left: 8px;
            }
          }

          .copy-container {
            margin-top: 20px;
            position: relative;

            .about-title {
              font-weight: 400;
              font-size: 16px;
              line-height: 18px;
              color: #FFFFFF;
              position: absolute;
              bottom: -30px;
              left: 0;
              @media only screen and (max-width: 767px) {
                position: relative;
                margin-bottom: 15px;
                bottom: unset;
                &:nth-of-type(1) {
                  margin-top: 15px;
                }
              }

              &:last-child {
                right: 0;
                left: unset;
              }
            }

            input {
              @media only screen and (max-width: 1365px) {
                width: 100%;
              }
            }
          }
        }

        .right {
          margin-left: 30px;
          @media only screen and (max-width: 1365px) {
            margin-left: 0;
            margin-top: 60px;
          }

          .title {
            margin-top: 14px;

            &:first-child {
              margin-top: 0;
            }
          }

          span {
            font-size: 24px;
            color: #FFFFFF;
            margin-right: 28px;
            @media only screen and (max-width: 1800px) {
              font-size: 20px;
              margin-right: 15px;
            }
          }
        }
      }

    }

    .bottom {
      @include space-between;
      margin-top: 60px;
      @media only screen and (max-width: 500px) {
        display: block;
      }
      @media only screen and (max-width: 1023px) {
        margin-top: 35px;
      }

      .about-container {
        @include flex;

        .title {
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          color: white;
          max-width: 65%;
          margin-left: 28px;
          @media only screen and (max-width: 1800px) {
            font-size: 16px;
            line-height: 24px;
          }

          @media only screen and (max-width: 500px) {
            max-width: 100%;
          }
        }
      }

      ::v-deep .button-container {
        margin-top: 30px;
        border-radius: 80px;
        padding: 0 45px;
        width: fit-content;
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
          padding: 0 32px;
          height: 50px;
        }
        @media only screen and (max-width: 500px) {
          width: 100%;
          @include center-children;
        }

        .button-title {
          font-weight: 500;
        }
      }
    }

  }

  .timer {
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
  }

  .table-container {
    padding-top: 20px;

    .title {
      color: white;
      font-size: 28px;
      margin-bottom: 20px;
      display: block;
    }

    ::v-deep .v-data-table {
      background: #1e1e1e;
      @media only screen and (max-width: 1023px) {
        background: #171717;
      }

      &.v-data-table--mobile {
        tbody {
          @media only screen and (max-width: 599px) {
            display: table-row-group !important;
          }
        }

        .v-data-table-header-mobile {
          display: none;
        }

        .v-data-table__mobile-row__header {
          font-family: 'Rubik';
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          color: #FFFFFF;
        }

        .v-data-table__mobile-row__cell {
          color: white;

          .title {
            color: white;
          }
        }

        .v-data-table__mobile-table-row {
          background: transparent;
          display: block;
          border: 1px solid rgba(250, 250, 250, .2);
          margin-top: 15px;
          border-radius: 10px;
          height: unset
        }
      }

      .text-start {
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        font-family: "Rubik";
      }

      .v-data-table-header {
        span {
          font-weight: 500;
          font-size: 22px;
          color: #FFFFFF;
          font-family: "Rubik";
          text-align: left;
        }
      }

      tr {
        height: 52px;
        background: #171717;
        @media only screen and (max-width: 1023px) {
          background: #1e1e1e;
        }

        &:hover {
          background: #171717 !important;
          @media only screen and (max-width: 1023px) {
            background: #1e1e1e;
          }
        }

        td, th {
          margin-bottom: 13px;
          border-bottom: 3px solid #1e1e1e !important;
          padding: 15px 30px;
          @media only screen and (max-width: 1023px) {
            border-bottom: 3px solid #171717 !important;
          }

          &:first-child {
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
          }

          &:last-child {
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
          }
        }

        .table-flex {
          @include flex;

          img {
            width: 30px;
            margin-left: 10px;
          }

          .title {
            margin-bottom: 0;
          }
        }
      }

      .v-data-table__empty-wrapper {
        td {
          padding: 50px 0;
          color: white;
          font-size: 22px;
        }
      }

    }
  }

}
</style>